import React from "react"
import Layout from "../../components/layout/layout"

import { FeedbackSurvey } from "../../components/feedback/feedbackSurvey"

import useIsClient from '../../useIsClient';

import Seo from "../../components/layout/seo";

export default function Fb() {

  const { isClient, key } = useIsClient();

  if ( !isClient ) return null;

  return (
    <Layout showBottom="false" key={key}>
      <Seo title="Drops | Feedback Survey | Toothpaste Drops" path="/surveys/fb" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <div style={{ minHeight: 'calc(100vh - 355px)' }}>
        <div style={{ paddingTop: '100px'}} className="container mb-5">
          <FeedbackSurvey />
        </div>
      </div>
    </Layout>
  )
}
