import React from "react"

import * as Survey from "survey-react";
import "survey-react/modern.css";

import axios from "axios";

const qs = require('qs');

const SURVEY_SEND_API_URL = "https://drops-crm-backend.herokuapp.com/api/webhook/survey";
// const SURVEY_SEND_API_URL = "http://localhost:8080/api/webhook/survey";

class FeedbackSurvey extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      referrer: "",
      origin: "",
      page: 1,
      initializeQeid: false,
      qeid: null,
      surveyData: null
    };
    this.doOnCurrentPageChanged = this.doOnCurrentPageChanged.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    this.setState({
      referrer: params.get('ref'),
      origin: params.get('origin'),
      page: 1,
      initializeQeid: false,
      qeid: null,
      surveyData: null
    });
  }

  json = {
 "locale": "de",
 "title": "Toothpaste Drops",
 "pages": [
  {
   "name": "page0",
   "elements": [
    {
     "type": "html",
     "name": "question7",
     "html": {
      "de": "Vielen Dank für Deine Unterstützung!<br><br>Es sind auch nur ein paar kurze Fragen und dauert keine 2 Minuten, versprochen.<br><br>Am Ende erwartet dich als Dankeschön auch eine kleine Überraschung!"
     }
    }
   ]
  },
  {
   "name": "page1",
   "elements": [
    {
     "type": "radiogroup",
     "name": "Wie zufrieden bist Du mit unseren Toothpaste Drops?",
     "isRequired": true,
     "choices": [
      {
       "value": "Sehr zufrieden",
       "text": {
        "de": "Sehr zufrieden"
       }
      },
      {
       "value": "Zufrieden",
       "text": {
        "de": "Zufrieden"
       }
      },
      {
       "value": "Leicht unzufrieden (Mir fehlt noch etwas)",
       "text": {
        "de": "Leicht unzufrieden (Mir fehlt noch etwas)"
       }
      },
      {
       "value": "Sehr unzufrieden (Ich bleibe lieber bei herkömmlicher Zahnpasta)",
       "text": {
        "de": "Sehr unzufrieden (Ich bleibe lieber bei herkömmlicher Zahnpasta)"
       }
      }
     ]
    }
   ]
  },
  {
   "name": "page2",
   "elements": [
    {
     "type": "checkbox",
     "name": "Mit welchen Eigenschaften warst du bei unseren Drops zufrieden?",
     "choices": [
      {
       "value": "Schaum",
       "text": {
        "de": "Schaum"
       }
      },
      {
       "value": "Frische",
       "text": {
        "de": "Frische"
       }
      },
      {
       "value": "Geschmack",
       "text": {
        "de": "Geschmack"
       }
      },
      {
       "value": "Reinigungsgefühl",
       "text": {
        "de": "Reinigungsgefühl"
       }
      },
      {
       "value": "Volumen",
       "text": {
        "de": "Volumen"
       }
      },
      {
       "value": "Konsistenz",
       "text": {
        "de": "Konsistenz"
       }
      }
     ],
     "hasOther": true,
     "otherText": {
      "de": "Etwas anderes:"
     },
     "hasSelectAll": true,
     "selectAllText": {
      "de": "Mit allem"
     }
    }
   ],
   "visibleIf": "{Wie zufrieden bist Du mit unseren Toothpaste Drops?} anyof ['other', 'Etwas enttäuscht', 'Sehr zufrieden', 'Zufrieden']"
  },
  {
   "name": "page3",
   "elements": [
    {
     "type": "text",
     "name": "Was hat Dir an unseren Drops nicht so gut gefallen?"
    }
   ],
   "visibleIf": "{Wie zufrieden bist Du mit unseren Toothpaste Drops?} anyof ['Leicht unzufrieden (Mir fehlt noch etwas)', 'Sehr unzufrieden (Ich bleibe lieber bei herkömmlicher Zahnpasta)']"
  },
  {
   "name": "page4",
   "elements": [
    {
     "type": "radiogroup",
     "name": "Hast du bereits andere Zahnpastatabletten ausprobiert?",
     "visibleIf": "{Wie zufrieden bist Du mit unseren Toothpaste Drops?} anyof ['Sehr zufrieden', 'Zufrieden']",
     "choices": [
      {
       "value": "Ja",
       "text": {
        "de": "Ja"
       }
      },
      {
       "value": "Nein",
       "text": {
        "de": "Nein"
       }
      }
     ]
    }
   ]
  },
  {
   "name": "page5",
   "elements": [
    {
     "type": "checkbox",
     "name": "Benutzt du zusätzlich Mundpflegeartikel?",
     "visibleIf": "{Wie zufrieden bist Du mit unseren Toothpaste Drops?} anyof ['Sehr zufrieden', 'Zufrieden']",
     "choices": [
      {
       "value": "Zungenschaber",
       "text": {
        "de": "Zungenschaber"
       }
      },
      {
       "value": "Zahnseide",
       "text": {
        "de": "Zahnseide"
       }
      },
      {
       "value": "Zahnzwischenraumbürsten",
       "text": {
        "de": "Zahnzwischenraumbürsten"
       }
      },
      {
       "value": "Mundwasser",
       "text": {
        "de": "Mundwasser"
       }
      },
      {
       "value": "Mundziehöl",
       "text": {
        "de": "Mundziehöl"
       }
      }
     ],
     "hasOther": true,
     "otherText": {
      "de": "Etwas anderes"
     }
    }
   ]
  },
  {
   "name": "page6",
   "elements": [
    {
     "type": "radiogroup",
     "name": "Aus wievielen Personen besteht dein Haushalt?",
     "visibleIf": "{Wie zufrieden bist Du mit unseren Toothpaste Drops?} anyof ['Sehr zufrieden', 'Zufrieden']",
     "choices": [
      {
       "value": "1",
       "text": {
        "de": "1"
       }
      },
      {
       "value": "2",
       "text": {
        "de": "2"
       }
      },
      {
       "value": "3",
       "text": {
        "de": "3"
       }
      },
      {
       "value": "4",
       "text": {
        "de": "4"
       }
      },
      {
       "value": "mehr als 4",
       "text": {
        "de": "mehr als 4"
       }
      }
     ]
    },
    {
     "type": "radiogroup",
     "name": "Leben Kinder bei dir im Haushalt?",
     "visibleIf": "{Aus wievielen Personen besteht dein Haushalt?} anyof [2, 3, 4, 'mehr als 4']",
     "choices": [
      {
       "value": "Ja",
       "text": {
        "de": "Ja"
       }
      },
      {
       "value": "Nein",
       "text": {
        "de": "Nein"
       }
      }
     ]
    }
   ]
  },
  {
   "name": "page7",
   "elements": [
    {
     "type": "text",
     "name": "Gibt es noch etwas, was wir an den Drops verbessern könnten?",
     "visibleIf": "{Wie zufrieden bist Du mit unseren Toothpaste Drops?} anyof ['Sehr zufrieden', 'Zufrieden']"
    }
   ]
  },
  {
   "name": "page8",
   "elements": [
    {
     "type": "radiogroup",
     "name": "Würdest du uns einer/m Freund/in empfehlen?",
     "visibleIf": "{Wie zufrieden bist Du mit unseren Toothpaste Drops?} anyof ['Sehr zufrieden', 'Zufrieden']",
     "choices": [
      {
       "value": "Ja",
       "text": {
        "de": "Ja"
       }
      },
      {
       "value": "Nein",
       "text": {
        "de": "Nein"
       }
      }
     ]
    }
   ]
  },
  {
   "name": "page9",
   "elements": [
    {
     "type": "radiogroup",
     "name": "Würdest du in Zukunft wieder bei uns kaufen?",
     "visibleIf": "{Wie zufrieden bist Du mit unseren Toothpaste Drops?} anyof ['Sehr zufrieden', 'Zufrieden']",
     "choices": [
      {
       "value": "Ja",
       "text": {
        "de": "Ja"
       }
      },
      {
       "value": "Nein",
       "text": {
        "de": "Nein"
       }
      }
     ]
    },
    {
     "type": "text",
     "name": "Was hält dich davon ab?",
     "visibleIf": "{Würdest du in Zukunft wieder bei uns kaufen?} = 'item2'"
    }
   ]
  }
 ],
 "cookieName": "drops_survey",
 "showTitle": false,
 "showProgressBar": "top",
 "startSurveyText": "Umfrage starten",
 "firstPageIsStarted": true
};


    doOnCurrentPageChanged(survey, options) {
      const headers = {
        'apikey': 'JeO93y6kNGVoKkNRun5JGFTM406r7w',
        'Content-Type': 'application/x-www-form-urlencoded'
      }

      // update current page and only store when current page > original page state
      // store to DB
      if (this.state.initializeQeid === false && this.state.qeid === null && this.state.referrer !== '') {
        const postData = qs.stringify({
          'type': 'feedback_survey',
          'referrer': this.state.referrer,
          'origin': this.state.origin
        });
        axios.post(SURVEY_SEND_API_URL, postData, { headers }).then(response => {
          this.setState({qeid: response.data.data.qeid});
          this.setState({surveyData: response.data.data.surveyResponse});
          window.localStorage.setItem('qeid', response.data.data.qeid);
          window.localStorage.setItem('fb_survey_completed', response.data.data.resultData);
          window.localStorage.setItem('customer_name', response.data.name);
          window.localStorage.setItem('full_name', response.data.fullName);
          window.localStorage.setItem('email', this.state.referrer);
        }).catch(function (error) {
          console.log(error);
          if (error.response.status === 403) {
            window.location.href = "/";
          }
        });
        this.setState({initializeQeid: true});
      }

      if (parseInt(survey.currentPage.propertyHash.name.replace('page', '')) > 1) {
        const postData = qs.stringify({
          'data': survey.data,
          'qeid': this.state.qeid
        })
        axios.post(SURVEY_SEND_API_URL, postData, { headers }).then(response => {
          console.log("data stored");
        });
      }
    }

    //Define a callback methods on survey complete
    onComplete(survey) {
       //Write survey results into database
      const headers = {
        'apikey': 'JeO93y6kNGVoKkNRun5JGFTM406r7w',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      const postData = qs.stringify({
        'data': survey.data,
        'completed': 1,
        'qeid': this.state.qeid
      })
      axios.post(SURVEY_SEND_API_URL, postData, { headers }).then(response => {
        if (survey.data['Wie zufrieden bist Du mit unseren Toothpaste Drops?'] === 'Leicht unzufrieden (Mir fehlt noch etwas)' || survey.data['Wie zufrieden bist Du mit unseren Toothpaste Drops?'] === 'Sehr unzufrieden (Ich bleibe lieber bei herkömmlicher Zahnpasta)') {
          // if negative, redirect to Circle
          window.location.href = "/surveys/fbresult?fb=2";
        } else {
          // if (survey.data['Würdest du uns einer/m Freund/in empfehlen?'] === 'Ja') {
            // redirect to recommendation WA
            window.location.href = "/surveys/fbresult?fb=1";
          // } else {
            // redirect to 20% off
            // window.location.href = "/surveys/fbresult?fb=0";
          // }
        }
      });
    }



    render() {

      const { surveyData } = this.state

      Survey
      .StylesManager
      .applyTheme("modern");


      //Create the model and pass it into react Survey component
      //You may create survey model outside the render function and use it in your App or component
      //The most model properties are reactive, on their change the component will change UI when needed.
      var model = new Survey.Model(this.json);

      if (surveyData !== null) {
        model.data = surveyData;
      }

      this.doOnCurrentPageChanged(model);
      return (
        <Survey.Survey model={model} showCompletedPage={false} onCurrentPageChanged={this.doOnCurrentPageChanged} onComplete={this.onComplete}/>
      );
      /*
      //The alternative way. react Survey component will create survey model internally
      return (<Survey.Survey json={this.json} onComplete={this.onComplete}/>);
      */
      //You may pass model properties directly into component or set it into model
      // <Survey.Survey model={model} mode="display"/>
      //or
      // model.mode="display"
      // <Survey.Survey model={model}/>
      // You may change model properties outside render function.
      //If needed react Survey Component will change its behavior and change UI.
   }
}

export { FeedbackSurvey }
